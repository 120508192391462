.UnknownGridLayer {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 50px auto 60% 50px;
    grid-template-rows: 30px 10% 12% 35% auto;
    gap: 1px 1px;
    grid-template-areas: ". . . ." ". . NavBar ." ". . . ." ". TitleText TitleText ." ". . . .";
    z-index: 5;
    padding-bottom: 100px;
}

.UnknownAccentLayer {
    top: 0;
    height: 100%;
    width: 100vw;
    background: #c5cde0;
    opacity: .6;
    z-index: 1;
}


/*
  _           _    _
 | |     ___ | |_ | |_  ___  _ __  ___
 | |    / _ \| __|| __|/ _ \| '__|/ __|
 | |___|  __/| |_ | |_|  __/| |   \__ \
 |_____|\___| \__| \__|\___||_|   |___/

 */
.UnknownGridLayer > .PageTitle {
    opacity: 1;
    display: block;
    position: absolute;
    top: 12%;
}

.UnknownGridLayer > .PageSubTitle {
    color: rgba(255, 255, 255, 0.9);
    /*border: 3px solid rgba(255, 255, 255, 0.8);*/
    background: rgba(41, 120, 160, 0.9);
}

.UnknownGridLayer > .PageTitle > #unknown_letters {
    fill: #2978A0;
}


.UnknownGridLayer > .PageTitle:hover #unknown_letters {
    /*font-family: Roboto-regular, sans-serif;*/
    /*fill: rgba(255, 255, 255, 0.42);*/
    stroke: #2978A0;
    stroke-width: .2vw;
    stroke-miterlimit: 10;
    overflow: visible;
    animation: letters_transition_color 2s ease forwards;
    animation-delay: 1s;
    /*animation-play-state: running;*/
}

@keyframes letters_transition_color {
    to {
        fill: rgba(0, 21, 49, 0.1);
        stroke: white;
    }
}

#unknown_letters path * {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
}

#unknown_letters path:nth-child(7) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1263;
}

#unknown_letters path:nth-child(8) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1076;
}

#unknown_letters path:nth-child(9) {
    stroke-dasharray: 300;
    stroke-dashoffset: 1633;
}

@media only screen and (max-width: 800px) {
    .UnknownGridLayer {
        width: 100vw;
        grid-template-columns: 6% 88% 6%;
        grid-template-rows: 50% 10% 10% 5% 20% auto;
        gap: 1px 1px;
        grid-template-areas: ". . ." ". TitleText ." ". . ." ". . ." ". NavBar ." ". . ."
    }

    .UnknownAccentLayer {
        background: #0e1340;
    }

    .UnknownGridLayer > .PageSubTitle {
        width: fit-content;
        height: fit-content;
        font-size: 2.4vh;
    }
}


@media only screen and (max-width: 550px) {
    .UnknownGridLayer {
        width: 100vw;
        grid-template-columns: 6% 88% 6%;
        /*grid-template-rows: 36% 10% 20% 5% 20% auto;*/
        grid-template-rows: 40% 10% 20% 5% 20% auto;
        gap: 1px 1px;
        grid-template-areas: ". . ." ". TitleText ." ". . ." ". . ." ". NavBar ." ". . ."
    }

    .UnknownAccentLayer {
        background: #0e1340;
    }
}